import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { debounce, cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { colors } from '../styleConstants';
import AddIcon from '../icons/AddIcon';
import Button from '../elements/Button';
import DateInput from '../elements/DateInput';
// import ExportIcon from '../icons/ExportIcon'; // TODO: Add later (https://app.clickup.com/t/2np7jj6)
import FlexContainer from '../elements/FlexContainer';
import FoodRow from './FoodRow';
import RecipeRow from './RecipeRowWeb';
import ModalContainer from '../sharedModals/ModalContainer';
import useModal from '../hooks/useModal';
import { formatDate } from '../helpers/date';

const ShoppingListWeb = ({
  shoppingList,
  recipes,
  addFoodToShoppingList,
  removeFoodFromShoppingList,
  removeRecipeFromShoppingList,
  adjustQuantity,
  setFoodCompleted,
  clearCompleted,
  values,
  handleChange,
  handleValueChanged,
  selectedRecipe,
  handleRecipeClick,
  categories,
  ...props
}) => {
  const { open, launchModal, closeModal, context } = useModal(false);
  const [newItem, setNewItem] = useState(null);
  const prevShoppingListRef = useRef();
  const dateFrom = formatDate(values.dateFrom);
  const dateTo = formatDate(values.dateTo);

  const handleDate = (e) => {
    e.target.name = formatDate(e.target.name);
    handleChange(e);
  };

  const handleDateValueChanged = (name, value, type) => {
    const newValue = formatDate(value);
    handleValueChanged(name, newValue, type);
  };

  useEffect(() => {
    if (!selectedRecipe) {
      if (
        prevShoppingListRef?.current?.length &&
        shoppingList.length > prevShoppingListRef?.current?.length
      ) {
        const prevIds = prevShoppingListRef?.current.map((food) => food.foodId);
        const newFood = shoppingList.filter(
          (food) => prevIds.indexOf(food.foodId) === -1,
        )[0];
        setNewItem(newFood);
      }
      //assign the ref's current value to the count Hook
      prevShoppingListRef.current = shoppingList;
    }
  }, [shoppingList]);

  // Generate component lists
  const categoryLists = categories.map((category, i) => {
    const foodList = shoppingList
      .filter((food) => food?.quantity > 0 && food?.category === category)
      .map((food, i, arr) => (
        <FoodRow
          key={food?.foodId}
          food={food}
          handleComplete={setFoodCompleted}
          handleRemove={removeFoodFromShoppingList}
          handleQuantity={debounce(adjustQuantity, 500)}
          isLastRow={i === arr.length - 1}
          newItem={newItem}
          disabled={selectedRecipe}
        />
      ));
    return (
      <CategoryContainer flexDirection="column" data-test="slCategory" key={i}>
        <CategoryHeaderBanner alignItems="center" addSpace={!foodList.length}>
          <CategoryHeader data-test="slCategory-name">
            {category === 'other' ? 'Additional Items' : category}
          </CategoryHeader>
        </CategoryHeaderBanner>
        {foodList}
      </CategoryContainer>
    );
  });

  const recipeList = cloneDeep(recipes)
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map((recipe, i) => (
      <RecipeRow
        key={i}
        recipe={recipe}
        handleClick={(e) => handleRecipeClick(e, recipe)}
        handleRemove={removeRecipeFromShoppingList}
        isSelected={selectedRecipe?.instanceId === recipe.instanceId}
        isFiltered={
          selectedRecipe && selectedRecipe?.instanceId !== recipe.instanceId
        }
        userId={props.userId}
      />
    ));

  return (
    <PageContainer alignItems="flex-start">
      <FoodContainer flexDirection="column">
        <TopContainer justify="space-between" alignItems="center">
          <Header>My Shopping List</Header>
          <FlexContainer justify="space-between">
            <AddIcon
              labelText="Add Food"
              orientation="row"
              pink="true"
              onClick={launchModal}
              data-hide="recipe"
            />
            {/* <ExportIcon /> TODO: Add export functionality later https://app.clickup.com/t/2np7jj6 */}
          </FlexContainer>
        </TopContainer>
        <CategoriesContainer flexDirection="column">
          {categoryLists}
        </CategoriesContainer>
        <AddContainer>
          <AddIcon
            labelText="Add Food"
            orientation="row"
            pink="true"
            onClick={launchModal}
            data-test={'shoppingList-add'}
            data-hide="recipe"
          />
          <ModalContainer
            open={open}
            context={{ ...context, type: 'shoppinglist' }}
            handleClose={closeModal}
            initialScreen="Meal Search"
            addFood={addFoodToShoppingList}
            {...props}
          />
        </AddContainer>
        <ButtonContainer alignSelf="center" alignItems="center">
          <Button
            buttonText="Clear Completed"
            buttonSize="small"
            width="224px"
            handleClick={clearCompleted}
            data-test="shoppingList-clearCompleted"
          />
        </ButtonContainer>
      </FoodContainer>
      <RecipeContainer flexDirection="column">
        <DateContainer flexDirection="column">
          <SubHead>Date Range:</SubHead>
          <FlexContainer alignItems="center">
            <DateInput
              name="dateFrom"
              width="100px"
              value={dateFrom || DateTime.now()}
              onValueChanged={debounce(handleDateValueChanged, 500)}
              onChange={handleDate}
              dateFormat="MMM d"
              data-test="dateFrom"
            />
            <Caption>to</Caption>
            <DateInput
              name="dateTo"
              width="100px"
              value={dateTo || DateTime.now()}
              onValueChanged={debounce(handleDateValueChanged, 500)}
              onChange={handleDate}
              dateFormat="MMM d"
              data-test="dateTo"
            />
          </FlexContainer>
        </DateContainer>
        <RecipeList flexDirection="column">
          <SubHead>Recipes Included:</SubHead>
          {recipeList}
        </RecipeList>
      </RecipeContainer>
    </PageContainer>
  );
};

const PageContainer = styled(FlexContainer)`
  padding: 16px;
  position: relative;

  > :first-child {
    margin-right: 16px;
  }
`;

const TopContainer = styled(FlexContainer)`
  padding: 0 32px;
  height: 97px;
  width: calc(100% - 64px);
  position: absolute;
  top: 0;
  border-bottom: 1px solid ${colors.primary100};
`;

const CategoriesContainer = styled(FlexContainer)`
  overflow-y: scroll;
  margin-top: 96px;
`;

const FoodContainer = styled(FlexContainer)`
  min-width: 400px;
  max-height: 1200px;
  flex-basis: 60%;
  border: 1px solid ${colors.primary200};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
`;

const Header = styled.h2`
  color: ${colors.primary700};
`;

const RecipeContainer = styled(FlexContainer)`
  min-width: 470px;
  max-height: 1000px;
  overflow-y: scroll;
  flex-basis: 35%;
  border: 1px solid ${colors.primary200};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 18px;
`;

const ButtonContainer = styled(FlexContainer)`
  padding-top: 24px;
  padding-bottom: 40px;
  .MuiButton-containedPrimary {
    background-color: ${colors.secondary500};
    &:hover {
      background-color: ${colors.secondary400};
    }
  }
`;

const DateContainer = styled(FlexContainer)`
  margin-bottom: 24px;
`;

const RecipeList = styled(FlexContainer)``;

const CategoryContainer = styled(FlexContainer)``;

const CategoryHeaderBanner = styled(FlexContainer)`
  background-color: ${colors.primary050};
  height: 40px;
  padding-left: 32px;
  margin-bottom: ${(props) => (props.addSpace ? '24px' : 0)};
`;

const CategoryHeader = styled.p`
  color: ${colors.primary600};
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
`;

const SubHead = styled.h5`
  color: ${colors.primary800};
  font-weight: bold;
  margin-bottom: 16px;
`;

const Caption = styled.p`
  color: ${colors.primary600};
  font-size: 13px;
  margin: 0 12px;
`;

const AddContainer = styled(FlexContainer)`
  margin-top: 16px;
  padding-left: 32px;
`;

export default ShoppingListWeb;
